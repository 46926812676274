module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/arvid/code/anysphere/website/landing-post-mortem/node_modules/gatsby-remark-footnotes","id":"1e4c03b3-e345-5c4f-82a2-e62c3b696af1","name":"gatsby-remark-footnotes","version":"0.0.8","modulePath":"/Users/arvid/code/anysphere/website/landing-post-mortem/node_modules/gatsby-remark-footnotes/index.js","pluginOptions":{"plugins":[],"footnoteBackRefInnerText":"↩︎","footnoteBackRefPreviousElementDisplay":"inline","footnoteBackRefDisplay":"inline"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/arvid/code/anysphere/website/landing-post-mortem","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
